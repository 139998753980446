var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FilterTable', {
    attrs: {
      "trigger": _vm.triggerReset,
      "name": "vendors categories",
      "container": true,
      "fetch-items": this.vendorGetVendorcategories,
      "span-method": _vm.handleSpan,
      "namespace": "vendorCategories",
      "columns": _vm.columns,
      "limit": 30,
      "search-filters": ['search']
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }