<template>
  <div>
    <FilterTable
      :trigger="triggerReset"
      name="vendors categories"
      :container="true"
      :fetch-items="this.vendorGetVendorcategories"
      :span-method="handleSpan"
      namespace="vendorCategories"
      :columns="columns"
      :limit="30"
      :search-filters="['search']"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'
import FilterTable from '@/components/ui/FilterTable'
import moment from 'moment'

export default {
  name: 'AdminCategories',
  components: {
    FilterTable
  },
  mixins: [
    pageConfig,
    tableColumn
  ],
  data () {
    return {
      moment: moment,
      riskColor:{
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      },
      modalEdit: false,
      isEditing: false,
      target: false,
      triggerReset: false,
      form: {
        description:'',
        descriptionadmin: ''
      },
      ruleValidate: {},
      /**
             * @note createColumn -> @/mixins/tableColumnn.js
             */
      columns: [
        this.createColumn(this.$t('global.table.topcatgeory'), 'topcategory', 'Categories.topcategory', null, 200),
        this.createColumn(this.$t('global.table.name'), 'name', 'Categories.name', null, 200),
        {
          title: this.$t('global.filters.is_exempted'),
          sortable: 'custom',
          sortKey: 'Categories.is_exempted',
          width: 145,
          render: (h, params) => {
            const render = h('Tag', {
              props: {
                color: 'blue'
              }
            }, 'Exempted')
            return params.row.is_exempted === 1 ? render : ''
          }
        },
        this.createColumn(this.$t('vigilance'), 'vigilance_id', 'Categories.vigilance_id', row => {
          const color = this.riskColor[this.vendorVigilanceToLetter(row.vigilance_id)]
          return {
            tag: 'Tag',
            props: {color, type: 'dot'},
            content: this.vendorVigilanceToLetter(row.vigilance_id)
          }
        },150),
        {
          title: 'Description',
          width: 'auto',
          render: (h, params) => {
            return h('p', {
              style: 'margin: 10px 0'
            }, params.row.description)
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      vendorGetVendorcategories: 'vendor/getVendorcategories',
    }),
    handleSpan () {

    },
    actionCat(isEditing, category) {
      this.target = category
      this.modalEdit = !this.modalEdit
      this.isEditing = isEditing
      this.form.desc = category.description
      this.form.descriptionadmin = category.descriptionadmin
    },
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter'
    })
  }
}
</script>
<style scope lang="scss">
.compliance-id {
  left: inherit!important;
  margin-left: 5px!important;
}
.title-span {
  font-weight: 700;
  p {
      font-weight: normal;
  }
  span:nth-child(1) {
    font-weight: normal;
  }
}

</style>